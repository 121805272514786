// 引入封装的第一层axios接口
import request from '@/utils/req.js'

//获取分类目录
export const getContents_api = (params) => {
    return request({
        url: '/api/regionIndex/getContents',
        method: 'get',
        params: {
            ...params
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}
//根据目录id查询子目录-只查询下一级
export const getSubClassifyById_api = (params) => {
    return request({
        url: '/api/regionIndex/getSubClassifyById',
        method: 'get',
        params: {
            ...params
        }
    })
}
//区域首页-分页查询
export const getRegionIndexPage_api = (params) => {
    return request({
        url: '/api/regionIndex/getRegionIndexPage',
        method: 'get',
        params: {
            ...params
        }
    })
}
//查询标签集合
export const getList_api = (params) => {
    return request({
        url: '/api/regionIndex/getList',
        method: 'get',
        params: {
            ...params
        }
    })
}
//查询分类集合
export const dictionary_api = (params) => {
    return request({
        url: '/api/regionIndex/dictionary',
        method: 'get',
        params: {
            ...params
        }
    })
}