<template>
  <el-container class="container">
    <el-header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{path:'/resource'}" :replace="true">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{this.$route.query.name}}</el-breadcrumb-item>
        <!-- <el-breadcrumb-item>党史学习</el-breadcrumb-item> -->
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container">
      <el-aside class="nav">
        <h5 class="title">{{this.$route.query.name}}</h5>
        <el-menu default-active="1" class="el-menu-vertical-demo" active-text-color="#00ff">
          <el-menu-item :index="(index+1).toString()" v-for="(item,index) in lists" :key="item.id"
            @click="selectList(item)">
            <span slot="title">{{item.name}}</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main class="list" v-loading='loading'>
        <el-table :cell-style="cellStyle" :data="tableData.records" style="width: 100%" :show-header="false"
          @row-click="toDetails">
          <el-table-column prop="name">
            <template slot-scope="scope">
              <div class="list-title">
                <img src="../../../../assets/img/list.png" class="icon-l" />
                <span>{{scope.row.name}}</span>
                <i class="el-icon-arrow-right icon-r"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination v-if="tableData.records" v-show="tableData.records.length>0"
          @current-change="handleCurrentChange" :current-page.sync="tableData.current" :page-size="tableData.size"
          layout="total, prev, pager, next" :total="tableData.total">
        </el-pagination>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import {
    getSubClassifyById_api,
    getRegionIndexPage_api
  } from "@/api/resourceCenter/region/search"
  export default {
    data() {
      return {
        loading: false,
        lists: [], //分类列表
        tableData: [],
        parms: {
          contentsId: '',
          current: 1,
          size: 10
        }
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      async init() {
        let list = await getSubClassifyById_api({
          "id": this.$route.query.id,
          "type": 1
        });
        this.lists = list.data;
        if (list.data.length > 0) {
          this.parms.contentsId = list.data[0].id
          this.getResourceList();
        }
      },
      async getResourceList() {
        this.loading = true;
        let resourceLists = await getRegionIndexPage_api(this.parms);
        this.tableData = resourceLists.data
        this.loading = false;
      },
      cellStyle(data) {
        if (data.columnIndex == 0) {
          return "cursor:pointer";
        }
      },
      handleCurrentChange(current) {
        this.parms.current = current;
        this.getResourceList();
      },
      //选择左侧菜单
      selectList(r) {
        this.parms.current = 1;
        this.parms.contentsId = r.id;
        this.getResourceList();
      },
      toDetails(row) {
        this.$router.push({
          path: '/resource/details',
          query: {
            id: row.id
          }
        })
      }
    }
  }
</script>

<style scoped>
  .container {
    background-color: #f6faff;
  }

  .el-breadcrumb {
    height: 40px;
    background-color: #f6faff;
    line-height: 40px;
  }

  .main-container {
    background-color: #ffff;
    width: 90%;
    margin: 0 auto;
    border-radius: 8px;
  }

  .el-main {
    padding: 0;
    border-top-right-radius: 8px;
  }

  .el-menu {
    border: 0;
  }

  .nav {
    width: 200px;
  }

  .nav .title {
    font-size: 16px;
    padding: 10px 20px;
  }

  .list {
    min-height: 600px;
    border-left: 1px #eee solid;
  }

  .list-title {
    height: 40px;
    line-height: 40px;
  }

  .list-title:hover {
    color: #1e62ec;
  }

  .icon-l,
  .icon-r {
    display: inline;
    vertical-align: middle;
  }

  .icon-l {
    padding-right: 10px;
  }

  .icon-r {
    float: right;
    line-height: 40px;
  }

  .el-table-column {
    cursor: pointer;
    background-color: #000;
  }

  .el-pagination {
    margin-top: 10px;
  }
</style>
